<template>
  <v-container class="my-container">
    <AlertError v-model="showAlert" :message="alertMessage"></AlertError>
    <v-row :class="{ 'center-content': isLoggedIn }">
      <v-col
        cols="12"
        :md="isLoggedIn ? '12' : '8'"
        order="2"
        order-sm="2"
        order-md="1"
        order-lg="1"
      >
        <v-card variant="elevated">
          <v-card-title
            style="display: flex; justify-content: center; flex-wrap: wrap"
            >C SabicoLearn ты будешь ...</v-card-title
          >
          <v-row
            style="
              display: flex;
              justify-content: space-between;
              margin-bottom: 2px;
            "
          >
            <v-col
              style="display: flex; flex-direction: column; align-items: center"
            >
              <v-img src="/img1.jpg" width="200"> </v-img>
              <div>
                <p><b>1) изучать слова по</b></p>
                <p><b>уровням</b></p>
                <br />
                <p style="font-style: italic">A1, A2, B1</p>
              </div>
            </v-col>
            <v-col
              style="display: flex; flex-direction: column; align-items: center"
            >
              <v-img src="/img2.jpg" width="200"> </v-img>
              <div>
                <p><b>2) изучать слова</b></p>
                <p><b> в трех формах</b></p>
                <br />
                <p style="font-style: italic">raamat, raamatu, raamatut</p>
              </div>
            </v-col>
            <v-col
              style="display: flex; flex-direction: column; align-items: center"
            >
              <v-img src="/img3.jpg" width="200"> </v-img>
              <div>
                <p><b>3) выбирать</b></p>
                <p><b>темы лексики</b></p>
                <br />
                <p style="font-style: italic">Mis kellani sa täna töötad?</p>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <router-link to="/register">
          <v-btn variant="elevated" class="text-none font-weight-bold mt-6 mb-6"
            >Зарегистрироваться</v-btn
          >
        </router-link>

        <p
          style="font-style: italic; color: #a59cd3; font-size: 30px"
          class="mt-10 mb-10 text-left"
        >
          Learn smart, not hard!
        </p>
      </v-col>

      <v-col
        cols="12"
        md="4"
        sm="12"
        v-if="!isLoggedIn"
        order="1"
        order-sm="1"
        order-md="2"
        order-lg="2"
      >
        <v-card variant="elevated">
          <h3 class="mt-5">У вас уже есть аккаунт? Войти!</h3>
          <v-responsive class="mx-auto mt-5" max-width="290">
            <v-text-field
              v-model="email"
              hide-details="auto"
              label="Адрес эл. почты"
              placeholder="johndoe@gmail.com"
              type="email"
              autofocus
              class="mb-5"
            ></v-text-field>
          </v-responsive>
          <v-responsive class="mx-auto" max-width="290">
            <v-text-field
              v-model="password"
              label="Пароль"
              type="password"
              hint="Enter your password to access this website"
              @keyup.enter="login"
              class="mb-2"
            ></v-text-field>
          </v-responsive>
          <div class="d-flex flex-column align-center">
            <v-btn
              class="text-none mx-auto mb-2"
              style="width: 290px"
              @click="login"
              >Войти</v-btn
            >
            <router-link to="/forgotPassword">
              <v-btn class="text-none mx-auto mb-2" style="width: 290px"
                >Забыли пароль?</v-btn
              >
            </router-link>
            <router-link to="/register" class="mb-4"
              >У вас еще нет аккаунта? Зарегистрироваться.</router-link
            >
          </div>
        </v-card>
      </v-col>
    </v-row>

    <h2 class="mt-5">Как это поможет тебе в изучении эстонского?</h2>

    <v-row
      :class="{
        'center-content': isLoggedIn,
      }"
      style="padding: 12px"
    >
      <v-card variant="elevated" class="mt-5">
        <v-row
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <v-col style="display: flex">
            <v-img src="/img1.jpg" height="300"> </v-img>
          </v-col>
          <v-col style="display: flex">
            <p style="margin-right: 30px">
              С SabicoLearn вы сэкономите время и можете изучать язык на том
              уровне, на котором вы сейчас находитесь. Вам не нужно проходить
              базовые слова, если вы уже их знаете. Сейчас в приложении три
              уровня - А1, А2 и В1.
            </p>
          </v-col>
        </v-row>
      </v-card>
      <v-card variant="elevated" class="mt-5">
        <v-row
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
          ><v-col style="display: flex">
            <v-img src="/img2.jpg" height="300"> </v-img>
          </v-col>
          <v-col style="display: flex">
            <p style="margin-right: 30px">
              Если вы знаете три формы слова и понимаете логику их
              использования, пазл эстонского языка сложится намного быстрее.
              Когда вы поймете, как это работает, то вы с легкостью осилите 14
              падежей!
            </p>
          </v-col></v-row
        >
      </v-card>
      <v-card variant="elevated" class="mt-5">
        <v-row
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
          ><v-col style="display: flex">
            <v-img src="/img3.jpg" height="300"> </v-img>
          </v-col>
          <v-col style="display: flex">
            <p style="margin-right: 30px">
              Мы учим язык, чтобы общаться. С SabicoLearn вы можете подготовится
              к походу к врачу или к собеседованию. Выберите актуальную для вас
              тему и тренируйте эстонский перед походом в кафе, магазин, к врачу
              или на новую работу!
            </p>
          </v-col></v-row
        >
      </v-card>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import axios from "axios";
import { useAuthStore } from "@/store/authStore";
import router from "@/router";
import { useUserStore } from "@/store/userStore";
import AlertError from "@/components/AlertError.vue";

export default defineComponent({
  name: "HomeView",
  components: { AlertError },
  setup() {
    const store = useAuthStore();
    const userStore = useUserStore();
    const email = ref("");
    const password = ref("");
    const isLoggedIn = computed(() => store.isAuthenticated);

    const showAlert = ref(false);
    const alertMessage = ref("");

    const handleError = (error: unknown) => {
      console.error("Login failed:", error);
      let errorMessage: string;

      if (axios.isAxiosError(error) && error.response) {
        const responseData = error.response.data as {
          message?: string | string[];
        };
        if (Array.isArray(responseData.message)) {
          errorMessage = responseData.message
            .map((msg: string) => `• ${msg}`)
            .join("\n");
        } else if (typeof responseData.message === "string") {
          errorMessage = responseData.message;
        } else {
          errorMessage = error.message || "An error occurred during login.";
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      } else {
        errorMessage = "An unknown error occurred during login.";
      }

      alertMessage.value = errorMessage;
      showAlert.value = true;
    };

    const login = async () => {
      try {
        const response = await axios.post("api/auth/login", {
          username: email.value,
          password: password.value,
        });

        const accessToken = response.data.accessToken;
        const refreshToken = response.data.refreshToken;
        const refreshTokenExpiry = response.data.refreshTokenExpiryDate;

        store.setToken(accessToken, refreshToken, refreshTokenExpiry);

        getProfile();
      } catch (error) {
        handleError(error);
      }
    };
    const getProfile = async () => {
      try {
        const response = await axios.get("api/users/profile");
        userStore.saveProfile(
          response.data.id,
          response.data.firstName,
          response.data.lastName,
          response.data.email,
          response.data.enabled,
          response.data.billingDate,
          response.data.lastLogin,
          response.data.lastPayment,
          response.data.role,
          response.data.tier,
        );
        router.push("/courses");
      } catch (error) {
        console.error("Fetching profile failed", error);
      }
    };
    return { email, password, login, isLoggedIn, showAlert, alertMessage };
  },
});
</script>

<style scoped>
.my-container {
  max-width: 1200px;
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add some padding for visual separation */
}

.styled-list li {
  padding-left: 1em;
  text-align: start;
}
</style>
