<template>
  <v-container class="my-container">
    <div class="terms-container">
      <v-card variant="elevated" style="padding: 20px" class="mb-5">
        <section class="terms-section">
          <h3>Условия использования</h3>
          <p>
            Данное приложение предлагает подписки следующих типов: на 1 месяц,
            на 3 месяца и на 1 год. Подписка автоматически продлевается по
            окончании текущего периода.
          </p>
          <br />
          <p>
            Вы можете отменить подписку в любое время, однако возврат средств не
            предусмотрен. Вы сможете продолжать пользоваться приложением до
            конца оплаченного периода.
          </p>
        </section>
      </v-card>
      <v-card variant="elevated" style="padding: 20px">
        <section class="terms-section">
          <h3>Политика конфиденциальности</h3>
          <p>
            Ваши личные данные и конфиденциальность имеют для нас первостепенное
            значение. Настоящая Политика конфиденциальности описывает, какие
            данные мы собираем, как мы их используем и как мы их защищаем в
            соответствии с Общим регламентом по защите данных (GDPR).
          </p>
          <br />

          <h3>Сбор данных</h3>
          <p>Мы можем собирать и обрабатывать следующие данные:</p>
          <ul>
            <li>
              Личная информация, такая как имя, адрес электронной почты и другие
              контактные данные, предоставленные вами при регистрации.
            </li>
            <li>
              Информация об использовании приложения, включая статистику
              использования и данные об активности.
            </li>
          </ul>
          <br />

          <h3>Использование данных</h3>
          <p>Собранные данные используются для:</p>
          <ul>
            <li>Обеспечения работы и улучшения нашего приложения.</li>
            <li>Обеспечения поддержки пользователей.</li>
            <li>Отправки обновлений и уведомлений о приложении.</li>
          </ul>
          <br />

          <h3>Защита данных</h3>
          <p>
            Мы принимаем все необходимые меры для защиты ваших данных от
            несанкционированного доступа, изменения, раскрытия или уничтожения.
            Ваши данные хранятся на защищённых серверах и обрабатываются в
            соответствии с требованиями GDPR.
          </p>
          <br />

          <h3>Права пользователей</h3>
          <p>
            В соответствии с GDPR вы имеете следующие права в отношении ваших
            данных:
          </p>
          <ul>
            <li>Право на доступ к вашим данным.</li>
            <li>Право на исправление неточной информации.</li>
            <li>Право на удаление данных («право быть забытым»).</li>
            <li>Право на ограничение обработки данных.</li>
            <li>Право на переносимость данных.</li>
            <li>Право на возражение против обработки данных.</li>
          </ul>
          <p>
            Если у вас есть вопросы или вы хотите воспользоваться своими
            правами, пожалуйста, свяжитесь с нами по [ваш контактный адрес
            электронной почты].
          </p>
          <br />

          <h3>Изменения в Политике конфиденциальности</h3>
          <p>
            Мы можем периодически обновлять эту Политику конфиденциальности. Мы
            будем уведомлять вас о любых изменениях, публикуя новую Политику
            конфиденциальности на этой странице.
          </p>
        </section>
      </v-card>
    </div>
  </v-container>
</template>

<script setup lang="ts"></script>

<style scoped>
.my-container {
  max-width: 1200px;
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add some padding for visual separation */
}

.terms-container {
  text-align: left;
  width: 80%;
  margin: 0 auto;
  padding: 20px;
}

.terms-section {
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

p {
  line-height: 1.6;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}
</style>

<!--<script lang="ts">-->
<!--import {defineComponent} from 'vue'-->

<!--export default defineComponent({-->
<!--  name: "TermsAndConditions"-->
<!--})-->
<!--</script>-->

<!--<template>-->

<!--</template>-->

<!--<style scoped>-->

<!--</style>-->
