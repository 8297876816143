<template>
  <template v-if="course.objectStatus === 'ACTIVE'">
    <v-card
      variant="elevated"
      class="ml-1 mr-1 mb-2 course-card"
      @click="handleStudyClick"
      hover
      width="450px"
    >
      <v-card-title class="font-weight-bold">{{ course.name }}</v-card-title>
      <v-card-text>{{ course.description }}</v-card-text>
      <v-card-text v-if="course && !course.userEnrollment">
        Слов: {{ course.wordCount }}
      </v-card-text>
      <v-card-text v-if="course && course.userEnrollment">
        <p>
          Слов выуч: {{ course.userEnrollment.completedWords }}/{{
            course.wordCount
          }}
        </p>
        <p>
          Предложений выуч: {{ course.userEnrollment.completedSentences }}/{{
            course.wordCount
          }}
        </p>
      </v-card-text>
    </v-card>
  </template>
  <template v-else>
    <v-card variant="elevated" class="ml-1 mr-1 mb-2 course-card">
      <v-card-title class="font-weight-bold">{{ course.name }}</v-card-title>
      <v-card-text>{{ course.description }}</v-card-text>
      <v-card-text v-if="course.wordCount > 0">
        <p>Слов: {{ course.wordCount }}</p>
        <p>Предложений: {{ course.topicCount }}</p>
      </v-card-text>
      <v-card-text class="font-weight-bold mt-3" style="color: dimgray">
        Скоро!
      </v-card-text>
    </v-card>
  </template>
</template>

<script>
import { useCourseStore } from "@/store/courseStore";
import { useRouter } from "vue-router";

export default {
  name: "CourseItem",
  props: {
    course: Object,
  },
  setup(props) {
    const courseStore = useCourseStore();
    const router = useRouter();

    const handleStudyClick = () => {
      courseStore.setCourse(props.course);
      router.push({ path: "/course", query: { showPopup: "true" } });
    };

    return { handleStudyClick };
  },
};
</script>

<style scoped>
.course-card {
  width: 450px;
  max-width: 100%; /* This will ensure your card does not exceed its container */
}
</style>
