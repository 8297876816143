<template>
  <v-container class="my-container">
    <v-dialog v-model="displayPopup" max-width="600px">
      <v-card variant="elevated">
        <v-card-text>{{ dialogMessage }}</v-card-text>
      </v-card>
    </v-dialog>
    <div class="flex-column align-center">
      <div>
        <h1 class="mb-3 ml-10 text-left">{{ courseName }}. {{ topicName }}</h1>
        <v-icon v-if="correctAnswerClicked" color="green-darken-2" size="50px"
          >mdi-check-circle-outline</v-icon
        >
        <v-icon v-if="incorrectAnswerClicked" color="red-darken-2" size="50px"
          >mdi-close-circle-outline</v-icon
        >
        <v-card
          :class="{
            'green-border': correctAnswerClicked,
            'red-border': incorrectAnswerClicked,
          }"
          variant="elevated"
          class="my-card mt-3 mb-3"
          style="width: 100%"
        >
          <v-col style="display: flex; justify-content: end; padding: 6px">
            <v-progress-circular
              :rotate="360"
              :size="60"
              :width="6"
              :model-value="topicProgressPercentage"
              color="#9575CD"
            >
              {{ topicProgressPercentage }}%
            </v-progress-circular>
          </v-col>
          <v-card-text class="mb-2">
            <h2>{{ word }}</h2>
          </v-card-text>
          <v-card-actions
            style="
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 100%;
            "
          >
            <v-btn
              v-for="button in buttonList"
              :key="button.value.id"
              @click="checkAnswer(button.value)"
              :class="{
                'correct-answer':
                  correctAnswerClicked && button.value.id === correctAnswer,
                'my-btn-disabled':
                  clickedButton && button.value.id !== correctAnswer,
                'highlight-correct':
                  correctAnswer && button.value.id === correctAnswer,
              }"
              variant="tonal"
              class="my-btn"
              :disabled="
                correctAnswerClicked && button.value.id !== correctAnswer
              "
            >
              {{ button.value.value }}
            </v-btn>
          </v-card-actions>
          <v-card-actions
            v-if="incorrectAnswerClicked || correctAnswerClicked"
            style="display: flex; justify-content: end"
          >
            <v-btn @click="fetchNewWord" variant="outlined">Дальше</v-btn>
          </v-card-actions>
        </v-card>
        <div class="d-flex justify-end mt-3">
          <router-link to="/course" class="mr-4">Темы</router-link>
          <router-link
            :to="courseType === 'LANGUAGE_LEVEL' ? '/courses' : '/exercises'"
            class="mr-4"
            >{{
              courseType === "LANGUAGE_LEVEL" ? "Курсы" : "Упражнения"
            }}</router-link
          >
        </div>
      </div>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useCourseStore } from "@/store/courseStore";
import axios from "axios";
import { useRouter } from "vue-router";

const correctAnswerClicked = ref(false);
const incorrectAnswerClicked = ref(false);
const clickedButton = ref("");

const router = useRouter();
const dialogMessage = ref("");
const displayPopup = ref(false);

const courseStore = useCourseStore();
const courseName = courseStore.currentCourse?.name;
const topicName = courseStore.currentTopic?.name;
const courseType = courseStore.currentCourse?.type;

const topic = ref();
const learnedWords = ref(0);
const totalWords = ref(0);
const topicProgressPercentage = ref("0");

onMounted(async () => {
  await fetchNewWord();
  await getTopic();
});

async function fetchNewWord() {
  correctAnswerClicked.value = false;
  incorrectAnswerClicked.value = false;
  clickedButton.value = "";

  try {
    const response = await axios.get(
      `api/course/${courseStore.currentCourse?.id}/topics/${courseStore.currentTopic?.id}/word`,
    );

    if (
      response.status === 200 &&
      (!response.data || Object.keys(response.data).length === 0)
    ) {
      displayPopup.value = true;
      dialogMessage.value = "Поздравляем! Вы выучили все слова в этой теме.";
      setTimeout(() => {
        displayPopup.value = false;
        router.push("/course");
      }, 3000);
      return;
    }
    // console.log(response.data);

    wordId.value = response.data.id;
    word.value = response.data.valueRu;
    correctAnswer.value = response.data.id;
    button1.value = response.data.wordOptions[0];
    button2.value = response.data.wordOptions[1];
    button3.value = response.data.wordOptions[2];
    button4.value = response.data.wordOptions[3];
  } catch (error) {
    console.error("Failed to fetch words", error.message);
  }
}

async function getTopic() {
  try {
    const response = await axios.get(
      `api/course/${courseStore.currentCourse?.id}/topics`,
    );
    const topics = response.data;

    //Find the current topic from the list of received topics
    topic.value = topics.find(
      (topic) => topic.id === courseStore.currentTopic?.id,
    );

    if (topic.value) {
      learnedWords.value = topic.value.completedWords || 0;
      totalWords.value = topic.value.wordCount || 0;
      topicProgressPercentage.value = totalWords.value
        ? Math.min((learnedWords.value / totalWords.value) * 100, 100).toFixed(
            1,
          )
        : "0";
    } else {
      console.error("Failed to find current topic in the list");
    }
  } catch (error) {
    console.error("Failed to fetch topics", error.message);
  }
}

const word = ref("");
const wordId = ref("");
const correctAnswer = ref("");
const button1 = ref({ id: "", value: "" });
const button2 = ref({ id: "", value: "" });
const button3 = ref({ id: "", value: "" });
const button4 = ref({ id: "", value: "" });
const buttonList = [button1, button2, button3, button4];

const checkAnswer = async (selectedAnswer: { id: string; value: string }) => {
  if (correctAnswerClicked.value || incorrectAnswerClicked.value) return;
  clickedButton.value = selectedAnswer.value;
  try {
    // console.log("Selected Answer:", selectedAnswer);
    // console.log("Correct Answer:", correctAnswer);
    // console.log("wordId:", wordId.value);

    const response = await axios.post(
      `api/course/${courseStore.currentCourse.id}/topics/${courseStore.currentTopic.id}/word`,
      {
        wordId: wordId.value,
        guessId: selectedAnswer.id,
      },
    );
    correctAnswer.value = response.data.correctValueId;

    // console.log(response.data);

    if (response.data.guessStatus === "CORRECT") {
      correctAnswerClicked.value = true;
      await getTopic();
    } else {
      incorrectAnswerClicked.value = true;
    }
  } catch (error) {
    console.error("Failed to check guess", error.message);
  }
};
</script>

<style scoped>
.my-container {
  max-width: 1700px;
  margin: 0 auto;
  padding: 20px; /* Add some padding for visual separation */

  /* Adjust padding for screens with a width of 1500px or less */
  @media screen and (max-width: 1500px) {
    padding: 0;
  }
}

.my-card {
  width: 100%;
}

.my-btn {
  flex: 1 0 calc(50% - 16px);
  margin: 8px;
  text-transform: lowercase;
}

@media screen and (max-width: 1500px) {
  .my-container {
    margin: 0 auto;
    padding: 20px;
  }

  .my-btn {
    flex: 1 0 calc(100% - 16px);
    margin: 8px;
    text-transform: lowercase;
  }
}

.green-border {
  border: 2px solid #388e3c;
}

.red-border {
  border: 2px solid #d32f2f;
}

.correct-answer {
  font-weight: bold;
  font-size: 1.2em;
}

.my-btn-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.highlight-correct {
  font-weight: bold;
  font-size: 1.2em; /* Adjust the font size as needed */
}
</style>
