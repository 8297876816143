<template>
  <v-container class="my-container">
    <h1 class="mb-3">Выбери Упражнение!</h1>
    <v-row justify="center">
      <div v-if="isLoading">Loading...</div>
      <CourseItem v-for="course in courses" :key="course.id" :course="course" />
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import CourseItem from "@/components/CourseItem.vue";
import axios from "axios";
import { onMounted, ref } from "vue";

const courses = ref([]);
const isLoading = ref(false);

const getCourses = async () => {
  isLoading.value = true;
  try {
    const response = await axios.get("api/course/list");
    courses.value = response.data.filter((course) => course.type === "SUBJECT");
  } catch (error) {
    console.error("Failed to fetch courses", error.message);
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  getCourses();
});
</script>

<style scoped>
.my-container {
  max-width: 1200px;
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add some padding for visual separation */
}
</style>
