<template>
  <v-container class="my-container">
    <v-dialog v-model="displayPopup" max-width="600px">
      <v-card variant="elevated">
        <v-card-text>{{ dialogMessage }}</v-card-text>
      </v-card>
    </v-dialog>
    <div class="flex-column align-center">
      <form @submit.prevent="formSubmitted" @keydown.prevent.enter>
        <!-- Block 1: Display original content with Hint button -->
        <template v-if="!showCorrectAnswer">
          <h1 class="mb-3 ml-10 text-left">
            {{ courseName }}. {{ topicName }}
          </h1>
          <v-card variant="elevated" class="mt-3 mb-3 my-card">
            <v-col style="display: flex; justify-content: end; padding: 6px">
              <v-progress-circular
                :rotate="360"
                :size="60"
                :width="6"
                :model-value="topicProgressPercentage"
                color="#9575CD"
              >
                {{ topicProgressPercentage }}%
              </v-progress-circular>
            </v-col>
            <v-card-text>
              <div class="sentence-container">
                <span class="sentence-part">{{ sentencePart1 }}</span>

                <v-text-field
                  v-model="userInput"
                  v-if="!correctAnswer"
                  class="ml-3 mr-3 sentence-text-field"
                  placeholder="Слово"
                  variant="underlined"
                  autofocus
                  @keyup.enter="handleKeyUp"
                ></v-text-field>
                <span class="sentence-part">{{ sentencePart2 }}</span>
              </div>
            </v-card-text>

            <v-card-text class="my-card-text">{{ russianWord }}</v-card-text>
            <v-divider></v-divider>
            <v-card-text>{{ russianFullSentence }}</v-card-text>

            <v-card-actions style="display: flex; justify-content: end">
              <v-btn
                @click="checkAnswer"
                v-if="!correctAnswer && userInput !== ''"
                variant="outlined"
                class="text-none my-btn"
              >
                Проверить
              </v-btn>
              <v-btn
                @click="showHintText"
                v-else-if="!correctAnswer"
                variant="outlined"
                class="text-none my-btn"
              >
                Показать ответ
              </v-btn>
            </v-card-actions>
          </v-card>
          <div class="d-flex justify-end mt-3">
            <router-link to="/course" class="mr-4">Темы</router-link>
            <router-link
              :to="courseType === 'LANGUAGE_LEVEL' ? '/courses' : '/exercises'"
              class="mr-4"
              >{{
                courseType === "LANGUAGE_LEVEL" ? "Курсы" : "Упражнения"
              }}</router-link
            >
          </div>
        </template>

        <!-- Block 2: Display correct answer with green/red border -->
        <div v-else>
          <v-icon v-if="correctAnswer" color="green-darken-2" size="50px"
            >mdi-check-circle-outline</v-icon
          >
          <v-icon v-if="incorrectAnswer" color="red-darken-2" size="50px"
            >mdi-close-circle-outline</v-icon
          >
          <v-card
            :class="{
              'green-border': showCorrectAnswer && userInput !== '',
              'red-border': incorrectAnswer && userInput !== '',
            }"
            variant="elevated"
            class="mt-3 mb-3 my-card"
          >
            <v-col style="display: flex; justify-content: end; padding: 6px">
              <v-progress-circular
                :rotate="360"
                :size="60"
                :width="6"
                :model-value="topicProgressPercentage"
                color="#9575CD"
              >
                {{ topicProgressPercentage }}%
              </v-progress-circular>
            </v-col>
            <v-card-text>
              <div class="sentence-container">
                <span class="sentence-part"
                  >{{ sentencePart1 }}
                  <span class="bold-answer">{{
                    correctAnswerValue
                  }}</span></span
                >
                <span
                  class="sentence-part"
                  v-if="showHint || showCorrectAnswer"
                >
                  {{ sentencePart2 }}
                </span>
              </div>
            </v-card-text>

            <v-divider></v-divider>
            <v-card-text>{{ russianFullSentence }}</v-card-text>
            <v-card-actions style="display: flex; justify-content: end">
              <v-btn
                v-focus
                @click="fetchNewSentence"
                @keyup.enter="fetchNewSentence"
                variant="outlined"
                class="text-none my-btn"
                type="button"
                >Дальше</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </form>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useCourseStore } from "@/store/courseStore";
import axios from "axios";
import { useRouter } from "vue-router";

const userInput = ref("");
const correctAnswer = ref(false);
const incorrectAnswer = ref(false);
const showHint = ref(false);
const showCorrectAnswer = ref(false);

const router = useRouter();
const dialogMessage = ref("");
const displayPopup = ref(false);

const courseStore = useCourseStore();
const courseName = courseStore.currentCourse?.name;
const topicName = courseStore.currentTopic?.name;
const courseType = courseStore.currentCourse?.type;

const topic = ref();
const learnedSentences = ref(0);
const totalWords = ref(0);
const topicProgressPercentage = ref("0");

onMounted(async () => {
  // console.log(
  //   `Course ID: ${courseStore.currentCourse.id}, Topic ID: ${courseStore.currentTopic.id}`,
  // );
  await fetchNewSentence();
  await getTopic();
});

const formSubmitted = async () => {
  if (!correctAnswer.value && userInput.value !== "") {
    await handleKeyUp();
  }
};

async function fetchNewSentence() {
  // console.log(
  //   `fetchNewSentence called. Course ID: ${courseStore.currentCourse.id}, Topic ID: ${courseStore.currentTopic.id}`,
  // );
  correctAnswer.value = false;
  incorrectAnswer.value = false;
  showCorrectAnswer.value = false;
  userInput.value = "";
  axios
    .get(
      `/api/course/${courseStore.currentCourse?.id}/topics/${courseStore.currentTopic?.id}/sentence`,
    )
    .then((response) => {
      if (
        response.status === 200 &&
        (!response.data || Object.keys(response.data).length === 0)
      ) {
        displayPopup.value = true;
        dialogMessage.value =
          "Поздравляем! Вы выучили все предложения в этой теме.";
        setTimeout(() => {
          displayPopup.value = false;
          router.push("/course");
        }, 3000);
        return;
      }

      // console.log(
      //   `Sentence ID: ${response.data.id}, Sentence: ${JSON.stringify(response.data)}`,
      // );
      sentenceId.value = response.data.id;
      const sentenceParts = response.data.textEt.split("x");
      sentencePart1.value = sentenceParts[0];
      sentencePart2.value = sentenceParts[1] || "";
      russianWord.value = response.data.valueRu;
      russianFullSentence.value = response.data.textRu;
    })
    .catch((error) => {
      console.error("Failed to fetch sentence", error.message);
    });
}

async function getTopic() {
  try {
    const response = await axios.get(
      `api/course/${courseStore.currentCourse?.id}/topics`,
    );
    const topics = response.data;

    //Find the current topic from the list of received topics
    topic.value = topics.find(
      (topic) => topic.id === courseStore.currentTopic?.id,
    );

    if (topic.value) {
      learnedSentences.value = topic.value.completedSentences || 0;
      totalWords.value = topic.value.wordCount || 0;
      topicProgressPercentage.value = totalWords.value
        ? Math.min(
            (learnedSentences.value / totalWords.value) * 100,
            100,
          ).toFixed(1)
        : "0";
    } else {
      console.error("Failed to find current topic in the list");
    }
  } catch (error) {
    console.error("Failed to fetch topics", error.message);
  }
}

// Variables for dynamic content from the backend
const sentenceId = ref("");
const sentencePart1 = ref("");
const sentencePart2 = ref("");
const russianWord = ref("");
const russianFullSentence = ref("");
const correctAnswerValue = ref("");

async function checkAnswer() {
  await axios
    .post(
      `/api/course/${courseStore.currentCourse?.id}/topics/${courseStore.currentTopic?.id}/sentence`,
      { sentenceId: sentenceId.value, guessValue: userInput.value },
    )
    .then((response) => {
      if (response.data.guessStatus === "CORRECT") {
        correctAnswer.value = true;
      } else {
        incorrectAnswer.value = true;
      }
      correctAnswerValue.value = response.data.correctValue;
      showCorrectAnswer.value = true;
    })
    .catch((error) => {
      console.error("Failed to check sentence", error.message);
    });
}

async function showHintText() {
  await axios
    .post(
      `/api/course/${courseStore.currentCourse?.id}/topics/${courseStore.currentTopic?.id}/sentence`,
      { sentenceId: sentenceId.value, guessValue: userInput.value },
    )
    .then((response) => {
      correctAnswerValue.value = response.data.correctValue;
    });
  showHint.value = true;
  showCorrectAnswer.value = true;
}

const handleKeyUp = async () => {
  if (userInput.value !== "") {
    await new Promise((r) => setTimeout(r, 100));
    await checkAnswer();
    await getTopic();
  } else {
    await new Promise((r) => setTimeout(r, 100));
    await showHintText();
  }
};
</script>

<style scoped>
.my-container {
  max-width: 1700px;
  margin: 0 auto;
  padding: 20px; /* Add some padding for visual separation */

  /* Adjust padding for screens with a width of 1500px or less */
  @media screen and (max-width: 1500px) {
    padding: 0;
  }
}

.my-card {
  width: 100%;
}

.my-card-text {
  font-weight: bold;
  font-size: 16px;
}

.my-btn {
  margin: 8px;
  margin-right: 30px;
}

.sentence-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  white-space: pre-wrap; /* Allow wrapping at spaces and other white-space characters */
}

.sentence-part {
  flex-grow: 0;
}

.sentence-text-field {
  flex-grow: 0;
  width: 100px;
}

.green-border {
  border: 2px solid #388e3c;
}

.red-border {
  border: 2px solid #d32f2f;
}

.bold-answer {
  font-weight: bold;
  font-size: 1.2em;
}

@media screen and (max-width: 1500px) {
  .my-container {
    margin: 0 auto;
    padding: 20px;
  }

  .my-btn {
    margin: 8px;
  }
}
</style>
