import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "area-1" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_main, { class: "area-2" }, {
        default: _withCtx(() => [
          _createElementVNode("nav", _hoisted_1, [
            _createVNode(_component_router_link, { to: "/" }, {
              default: _withCtx(() => [
                _createTextVNode("Домой | ")
              ]),
              _: 1
            }),
            (_ctx.isLoggedIn && _ctx.isAdmin)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 0,
                  to: "/dashboard"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Dashboard | ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isLoggedIn)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 1,
                  to: "/courses"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Курсы | ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isLoggedIn)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 2,
                  to: "/exercises"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Упражнения | ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_router_link, { to: "/about" }, {
              default: _withCtx(() => [
                _createTextVNode("Инфо | ")
              ]),
              _: 1
            }),
            (_ctx.isLoggedIn)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 3,
                  to: "/account"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Мой аккаунт | ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.isLoggedIn)
              ? (_openBlock(), _createBlock(_component_router_link, {
                  key: 4,
                  to: "/",
                  onClick: _ctx.logout
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Выйти")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.showButton && _ctx.isFree && _ctx.isLoggedIn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_router_link, { to: "/account" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_btn, {
                      variant: "elevated",
                      class: "buy-btn text-none mt-6 mb-6",
                      color: "#4B2D9F"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("Хочу оформить подписку")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}