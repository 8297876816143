<template>
  <v-container class="my-container">
    <div v-if="tier === 'FREE'">
      <div v-if="!userId">
        <span>Loading user data ... </span>
      </div>
      <div v-else>
        <stripe-pricing-table
          pricing-table-id="prctbl_1PSyYjRqHJMvBuOgrVsrjOI3"
          publishable-key="pk_live_51PBuT4RqHJMvBuOgNROhDOstXpPJexP2ruBCwDhTLLQfcTfskrCgMDVtuDrEd8VIW3Kd2L2uKRUOSWBsvafGAue600oRzZqXY9"
          :client-reference-id="userId"
        >
        </stripe-pricing-table>
        <a href="https://billing.stripe.com/p/login/dR68wO9sjaXebvy5kk">
          <v-btn class="mt-3 my-btn" color="#4B2D9F"
            >Управление подпиской
          </v-btn>
        </a>
      </div>
    </div>
    <v-card
      variant="elevated"
      class="my-card mt-3 mb-3 area-1"
      style="width: 100%"
    >
      <v-card-text>
        <h2 class="mb-3">Мой аккаунт</h2>
      </v-card-text>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col> <b>Имя:</b></v-col>
            <v-col>
              {{ firstName + " " + lastName }}
            </v-col>
          </v-row>
          <v-row>
            <v-col> <b>Подписка:</b></v-col>
            <v-col>
              {{ tier === "PAID" ? "Действует" : tier }}
            </v-col>
          </v-row>
          <v-row>
            <v-col> <b>Подписка активна до:</b></v-col>
            <v-col>
              {{ formatDate(paymentExpirationDate) }}
            </v-col>
          </v-row>
          <router-link to="/terms&conditions">
            <v-btn class="mt-4" variant="outlined">Условия использования</v-btn>
          </router-link>
        </v-container>
      </v-card-text>

      <!--      <v-card-actions>-->
      <!--        <v-btn variant="outlined">MUUDA SALASÕNA</v-btn>-->
      <!--      </v-card-actions>-->
    </v-card>
    <div v-if="tier === 'PAID'">
      <div v-if="!userId">
        <span>Loading user data ... </span>
      </div>
      <div v-else>
        <stripe-pricing-table
          pricing-table-id="prctbl_1PSyYjRqHJMvBuOgrVsrjOI3"
          publishable-key="pk_live_51PBuT4RqHJMvBuOgNROhDOstXpPJexP2ruBCwDhTLLQfcTfskrCgMDVtuDrEd8VIW3Kd2L2uKRUOSWBsvafGAue600oRzZqXY9"
          :client-reference-id="userId"
        >
        </stripe-pricing-table>
        <a href="https://billing.stripe.com/p/login/dR68wO9sjaXebvy5kk">
          <v-btn class="mt-3 my-btn" color="#4B2D9F"
            >Управление подпиской
          </v-btn>
        </a>
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import axios from "axios";
import { onMounted, ref } from "vue";
import { useUserStore } from "@/store/userStore";

export default {
  setup() {
    const userId = ref("");
    const firstName = ref("");
    const lastName = ref("");
    const tier = ref("");
    const paymentExpirationDate = ref("");
    const userStore = useUserStore();
    const formatDate = (dateString: string): string => {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, "0");
      const minutes = date.getMinutes().toString().padStart(2, "0");
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };
    const getProfile = async () => {
      try {
        const response = await axios.get("api/users/profile");
        userId.value = response.data.id;
        firstName.value = response.data.firstName;
        lastName.value = response.data.lastName;
        tier.value = response.data.tier;
        paymentExpirationDate.value = response.data.paymentExpirationDate;

        userStore.tier = response.data.tier;
      } catch (error) {
        console.error("Fetching profile failed", error);
      }
    };

    onMounted(getProfile);

    return {
      userId,
      firstName,
      lastName,
      tier,
      paymentExpirationDate,
      formatDate,
    };
  },
};
</script>

<style scoped>
.my-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px; /* Add some padding for visual separation */

  /* Adjust padding for screens with a width of 1500px or less */
  @media screen and (max-width: 1500px) {
    padding: 0;
  }
}

.my-card {
  width: 100%;
}

.my-btn >>> .v-btn__content {
  color: white !important;
}
</style>
